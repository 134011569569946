$block: 'c-navigation';
.#{$block} {
  align-items: center;
  background: $color-primary-50;
  display: flex;
  left: 0;
  min-height: $spacing-xxxl;
  padding: 0 $spacing-xs 0 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-30;

  &__selects,
  &__list,
  &__user {
    flex: 1;
  }

  &__selects {
    align-items: center;
    display: flex;
    flex: 1;
    position: relative;
    top: -2px;
  }

  &__collective {
    color: $color-white;
    font-weight: 500;
    margin-right: $spacing-s;
  }

  &__select {
    margin: 0;
    line-height: 1;

    &:not(:last-child) {
      margin-right: $spacing-s;
    }

    select {
      min-height: 60px;
    }
  }

  &__list {
    display: none;
    list-style: none;
    margin: 0;
    text-align: center;
    z-index: $z-index-30;
    justify-content: center;

    @media (min-width: $breakpoint-40) {
      position: relative;
      display: flex;
      box-shadow: none;
      background: transparent;
    }
  }

  &__item {
    border-left: 1px solid rgba($color-white, 0.15);

    &:last-child {
      border-right: 1px solid rgba($color-white, 0.15);
    }

    &--selected {
      background: $color-neutral-70;

      @media (min-width: $breakpoint-40) {
        background: transparent;
      }
    }
  }

  &__link {
    display: block;
    min-height: 64px;

    &:hover,
    .#{$block}__item--selected & {
      color: $color-white;
      background: $color-primary-90;
    }

    .#{$block}__item--selected & {
      font-weight: bold;
    }
  }

  &__link,
  &__user-name,
  &__logo,
  &__help {
    color: $color-white;
    padding: $spacing-m $spacing-m;
    position: relative;
    font-size: $font-size-m;
    transition: $timing-10 $bezier;
    font-weight: 500;
    line-height: 1;
  }

  &__logo {
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
    display: flex;
    align-items: center;
  }

  &__user-name {
    display: inline-block;
    position: relative;

    &:hover {
      color: $color-white;
    }

    &:after {
      $dimension: 4px;

      border-left: $dimension solid transparent;
      border-right: $dimension solid transparent;
      border-top: $dimension solid $color-white;
      content: '';
      height: 0;
      position: absolute;
      right: $spacing-xs;
      top: calc( 50% - 2px);
      width: 0;
      z-index: $z-index-10;
      transition: $timing-10;

      .c-navigation__user--open & {
        transform: rotate(180deg);
      }
    }
  }

  &__user {
    position: relative;
    text-align: right;
    flex: 0;

    @media (min-width: $breakpoint-40) {
      flex: 1;
    }
  }

  &__user-name {
    display: none;

    @media (min-width: $breakpoint-40) {
      display: inline-block;
    }
  }

  &__help {
    font-size: $font-size-xxs;
    padding: 14px 0 $spacing-s 0;
    text-transform: uppercase;
    display: block;

    @media (min-width: $breakpoint-40) {
      font-size: $font-size-m;
      text-transform: none;
      display: inline-block;
    }

    &:hover {
      color: $color-white;
    }

    &__icon {
      position: relative;
      fill: $color-white;

      @media (min-width: $breakpoint-40) {
        margin-right: $spacing-xxs;
      }
    }
  }
}
